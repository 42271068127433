.App {
  text-align: center;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e73737;
  text-align: center;
  min-height: 100vh;
}

#main-content {
  flex-grow: 1;
}

a {
  color: #e73737;
}

.big-link {
  font-size: 2em;
}

table {
  /* table-layout: fixed; */
  border-collapse: collapse;
}

tbody tr {
  letter-spacing: 0.03rem;
}

tbody tr:nth-child(odd) {
  background-color: #252525;
}

tbody tr:nth-child(even) {
  background-color: #202020;
}

th, td {
  padding: 0.25rem 0.5rem;
}

.max-width {
  width: 100%;
}

.winner {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  #main-content {
    padding: 0 0.5rem;
  }

  table {
    width: 100%;
  }

  table thead {
    display: none;
  }

  tbody tr {
    padding: 0 0.5rem;
  }

  table td {
    display: flex;
    text-align: left;
  }

  table td::before {
    content: attr(title);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
  }
}
.nav-container {
    display: flex;
    justify-content: left;
    background-color: #ce302f;
    color: white;
    height: 3rem;
    font-size: 18pt;
    width: 100%;
}

.main-link-container {
    margin-left: 1rem;
}

.main-link-container, .sub-link-container {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    align-items: center;
    display: flex;
}

.main-link, .sub-link {
    color: white;
    line-height: 3rem;
    text-decoration: none;
}

.main-link {
    font-weight: bold;
}

@media (hover: hover) {
    .main-link-container:hover, .sub-link-container:hover {
        background-color: #9a1f21;
    }
}

/* Nav can easily fit at 500px rather than the usual 600px mobile cutoff */
@media screen and (max-width: 500px) {
    .mobile-optional {
        display: none;
    }
}
.card {
    display: block;
    border: 2px solid #e73737;
    width: 300px;
    max-width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: white;
    text-decoration: none;
}

.card .card-image {
    padding: 12px;
    width: 200px;
}
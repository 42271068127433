.list-container {
    max-width: 1080px;
}

.card-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.card-list li {
    padding: 12px;
    margin: 0 auto;
}